// extracted by mini-css-extract-plugin
export var image = "case-gallery-module--image--u9Bt4";
export var imageWrapper = "case-gallery-module--image-wrapper--8S5lG";
export var infoContainer = "case-gallery-module--info-container--jD4BM type--font-museo--Rt53G undefined";
export var line = "case-gallery-module--line--DrSyE elements--divider--9oGzu";
export var numContainer = "case-gallery-module--num-container--8swKX type--font-essonnes--k0IEZ";
export var numCurrent = "case-gallery-module--num-current--6t1n6 type--heading-xxl--rf7Fn";
export var numTotal = "case-gallery-module--num-total--itPSD type--heading-s--JfTSy";
export var numWrapper = "case-gallery-module--num-wrapper--yasuf";
export var skills = "case-gallery-module--skills--daPTu type--text-m---eRFt";
export var skillsItem = "case-gallery-module--skills-item--VzqV- undefined";
export var skillsList = "case-gallery-module--skills-list--FZc5M";
export var slideContainer = "case-gallery-module--slide-container--u-MyR";
export var title = "case-gallery-module--title--UR4PI type--font-essonnes--k0IEZ type--heading-xxl--rf7Fn";
export var titleLink = "case-gallery-module--title-link--ztQ12";
export var titleWrapper = "case-gallery-module--title-wrapper--0pns3";
export var view = "case-gallery-module--view--dsimU type--text-m---eRFt";