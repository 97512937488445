// extracted by mini-css-extract-plugin
export var col4 = "tldr-module--col-4--tErd8 grid--col-md-4--DgprU";
export var col6 = "tldr-module--col-6--ReqdW grid--col-md-6--uS2Fp";
export var container = "tldr-module--container--3Ph+i grid--row--E93eT";
export var contentContainer = "tldr-module--content-container--HgCGi grid--col-md-10--GZEu1 grid--col-lg-9--KkUdn";
export var emoji = "tldr-module--emoji--lGRyK";
export var headContainer = "tldr-module--head-container--vAKAR grid--col-md-2--t2o+e grid--push-lg-1--nj0g8";
export var headWrapper = "tldr-module--head-wrapper--5EnW-";
export var header = "tldr-module--header--O+LZz type--font-essonnes--k0IEZ type--heading-l--Kf1i8";
export var row = "tldr-module--row--Ia1lT grid--row--E93eT";
export var skillItem = "tldr-module--skill-item--zjDYs type--text-m---eRFt";
export var skills = "tldr-module--skills--DEI7o";
export var subHead = "tldr-module--sub-head--rEeQf type--font-museo--Rt53G type--heading-xxs--yJOVJ";
export var summaryWrapper = "tldr-module--summary-wrapper--JkL2+ grid--col--Jq13z";
export var text = "tldr-module--text--sF+GW type--text-m---eRFt";